import React from 'react';
import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import HeroCTA from '@components/sections/heroCTA/heroCTA';
import SocialProofQuoteCardST from '@components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import GetStartedST from '@components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '@components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import OutcomeST from '@components/staticSections/OutcomeST/OutcomeST';
import Treatments from '@components/staticSections/Treatments/Treatments';
import HeaderImage from '@/src/images/logo-prs-2.png';

const IndexPage = () => {
  const lang = 'fr_fr';
  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
      lang="fr"
      headerImage={
        <img className="logo--sm margin-left--xs flex" alt="Projet Santé" src={HeaderImage} />
      }
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_FR} />
      <Seo title="Projet Santé" language="fr" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedataNoCerts
        dataSection="hero_CTA"
        alt=""
        title="Une appli pour soulager l’arthrose et les douleurs articulaires"
        titleClassName="bold"
        text="Un suivi par un kinésithérapeute agréé et des exercices adaptés pour soulager vos douleurs au quotidien."
        tagHeadline="Aucun frais – couvert par l’assurance maladie et votre mutuelle."
        smsTag
        dataToken="eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Njc0ODM5MzcsImp0aSI6ImQ5YTU5NmY3LTk3MjAtNDZjZi04NTNmLTVkODg2ZGEwNWI3ZiIsImFmZmlsaWF0ZV9uYW1lIjoiQWhtZWQgVyJ9.13eg56T2LAYinXhOblEV1wsIP1FRTA94Slt97au0RJ4"
      />
      <SocialProofQuoteCardST
        lang={lang}
        dataTheme="white"
        extraMargin="margin-top--lg"
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="C’EST PARTI !"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="#herotext"
      />
      <BgCardsTextST dataTheme="light" lang={lang} />
      <Treatments lang={lang} downloadhref="#herotext" />
      <SocialProofFeedST lang={lang} sectionClassName="margin-bottom--lg" />
      <OutcomeST lang={lang} />
    </Layout>
  );
};

export default IndexPage;
